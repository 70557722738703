









import {Component, Prop, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';

@Component
export default class ServiceUnavailable extends Vue {
  @Prop({default: false}) noFooter!: boolean;
  dismissError() {
    vxm.general.setServiceUnavailable(false);
  }
  get showError() {
    return vxm.general.serviceUnavailableError;
  }

  created() {
    setTimeout(function() {
      vxm.general.setServiceUnavailable(false);
    }, 5000);
  }
}
