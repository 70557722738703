
























import {Component, Vue, Watch} from 'vue-property-decorator';
import HeaderMenuNew from '@/components/HeaderMenuNew.vue';
import HeaderMenuModelNew from '@/components/HeaderMenuModelNew.vue';
import Footer from '@/components/Footer.vue';
import ServiceUnavailable from '@/components/ServiceUnavailable.vue';
import {vxm} from '@/store';

@Component({components: {ServiceUnavailable, HeaderMenuNew, HeaderMenuModelNew, Footer}})
export default class BasicLayout extends Vue {
  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  get isFanLayout() {
    return this.isLogin ? vxm.user.data.role !== 'performer' : true;
  }

  get homePath(): string {
    return this.isFanLayout ? '/fan/home' : '/model/dashboard';
  }

  checkRedirect(link: string) {
    if (!this.isLogin) {
      this.$router.push('login');
    } else if (this.$route.path !== link) {
      this.$router.push(link);
    }
  }
}
