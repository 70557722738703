
























































import {Component, Mixins, Prop} from 'vue-property-decorator';
import NumbersMixin from '@/mixins/NumbersMixin';
import {ModelDataInterface} from '@/types/userDataInterface';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';

@Component
export default class ModelCardNew extends Mixins(NumbersMixin, DefaultAvatarMixin) {
  @Prop({default: false}) dashboardView!: boolean;
  @Prop() readonly model!: ModelDataInterface;
  @Prop() readonly link!: ModelDataInterface;
  @Prop({default: false}) showModelDetails!: boolean;
}
