
















































import {Component, Mixins, Watch} from 'vue-property-decorator';
import BecomeAModel from '@/components/BecomeAModel.vue';
import DropDown from '@/components/DropDown.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {PerformersParamsInterface} from '@/types/PerformersParamsInterface';
import {vxm} from '@/store';
import ModelCardNew from '@/components/ModelCardNew.vue';
import Loader from '@/components/Loader.vue';
import DebounceMixin from '@/mixins/DebounceMixin';

@Component({
  components: {Loader, ModelCardNew, BecomeAModel, DropDown},
})
export default class ModelsNew extends Mixins(DebounceMixin) {
  selected = '';
  displayCount = 1;
  hardData = [
    {
      age: 34,
      createdAt: '2018-01-08T14:29:47.227Z',
      description: 'hey guys!! Welcome to my 4MF page. i post all my content here. 3 times a week i hope you enjoy!!',
      imageFullPath: 'https://formyfans.s3-us-west-1.amazonaws.com/20221118100627-450-avatar',
      imageMediumPath: 'https://formyfans.s3.us-west-1.amazonaws.com/20221118100627-450-avatar.320x320.jpeg',
      imageThumbPath: 'https://formyfans.s3.us-west-1.amazonaws.com/20221118100627-450-avatar.135x135.png',
      isPayable: true,
      isPerformer: true,
      isShowFans: false,
      name: 'Austin Wolf',
      photo: 'https://formyfans.s3.us-west-1.amazonaws.com/20221118100627-450-avatar.135x135.png',
      role: 'performer',
      sex: 'male',
      sexualPreference: 'gay',
      sort: 0,
      status: 'active',
      subscriptionMonthlyPrice: 9.99,
      subscriptionTrialEnabled: true,
      subscriptionTrialPrice: 3.99,
      subscriptionYearlyPrice: 109.99,
      totalLike: 77930,
      totalSubscriber: 5347,
      totalVideo: 3413,
      totalView: 6140721,
      updatedAt: '2023-08-31T14:43:52.054Z',
      username: 'austinwolf',
      weight: '255',
      welcomeOption: 'video',
      welcomeVideo:
        'https://formyfans.s3.us-west-1.amazonaws.com/videos/20220913101732-684-sequence-01_1/1920x1080.mp4?AWSAccessKeyId=AKIARQ6XZMK7LEXN4CMD&Expires=1694076868&Signature=3e680Q5jrM%2B6fR67sySeWSe%2B1OU%3D',
      _id: '5a53805bb244827d81b98218',
    },
    {
      age: 23,
      createdAt: '2022-04-05T11:56:33.956Z',
      description:
        'So This Is The 4my.fans Page!! You Wont Have The Same Problems U Have Had With The Other Sites Here. This Should Be Easier To Use And Easier To Find What You’re Looking For. I Update My Page 2 To Three Times A Week For Constant Simulation … I Have No End In Sight For Boys Than Wana Be In Videos, So Lets Keep Them Cumming!!',
      imageFullPath: 'https://formyfans.s3-us-west-1.amazonaws.com/20221202125146-640-avatar',
      imageMediumPath: 'https://formyfans.s3-us-west-1.amazonaws.com/20221202125146-640-avatar.320x320.jpeg',
      imageThumbPath: 'https://formyfans.s3-us-west-1.amazonaws.com/20221202125146-640-avatar.135x135.png',
      isPayable: true,
      isPerformer: true,
      isShowFans: true,
      name: 'Emily Rainey',
      photo: 'https://staging-4mf-bucket.s3.us-west-1.amazonaws.com/20221130034213-791-avatar.135x135.png',
      role: 'performer',
      sex: 'MTF',
      sexualPreference: 'gay',
      sort: 0,
      status: 'active',
      subscriptionMonthlyPrice: 9.99,
      subscriptionTrialEnabled: true,
      subscriptionTrialPrice: 2.99,
      subscriptionYearlyPrice: 49.99,
      totalLike: 18,
      totalSubscriber: 2,
      totalVideo: 457,
      totalView: 26.1,
      updatedAt: '2022-11-30T08:42:39.971Z',
      username: 'emilyrainey',
      weight: '130',
      welcomeOption: 'video',
      welcomeVideo:
        'https://staging-4mf-bucket.s3.us-west-1.amazonaws.com/videos/20220728020913-203-woman-47431/1920x1080.mp4?AWSAccessKeyId=AKIAZJ5CPRFFUGZSJ4UE&Expires=1673023900&Signature=t8ew%2F6JSz1P50YEoz0n6cd9yMvQ%3D',
      _id: '624c2e7170672e6923c757c5',
    },
    {
      age: 23,
      createdAt: '2022-04-05T11:56:33.956Z',
      description:
        'So This Is The 4my.fans Page!! You Wont Have The Same Problems U Have Had With The Other Sites Here. This Should Be Easier To Use And Easier To Find What You’re Looking For. I Update My Page 2 To Three Times A Week For Constant Simulation … I Have No End In Sight For Boys Than Wana Be In Videos, So Lets Keep Them Cumming!!',
      imageFullPath: 'https://formyfans.s3-us-west-1.amazonaws.com/2021218113544_avatar',
      imageMediumPath: 'https://formyfans.s3-us-west-1.amazonaws.com/2021218113544_avatar.320x320.jpeg',
      imageThumbPath: 'https://formyfans.s3-us-west-1.amazonaws.com/2021218113544_avatar',
      isPayable: true,
      isPerformer: true,
      isShowFans: true,
      name: 'John Bronco',
      photo: 'https://staging-4mf-bucket.s3.us-west-1.amazonaws.com/20221130034213-791-avatar.135x135.png',
      role: 'performer',
      sex: 'MTF',
      sexualPreference: 'gay',
      sort: 0,
      status: 'active',
      subscriptionMonthlyPrice: 9.99,
      subscriptionTrialEnabled: true,
      subscriptionTrialPrice: 2.99,
      subscriptionYearlyPrice: 49.99,
      totalLike: 1203,
      totalSubscriber: 2,
      totalVideo: 498,
      totalView: 62.4,
      updatedAt: '2022-11-30T08:42:39.971Z',
      username: 'johnbronco87',
      weight: '130',
      welcomeOption: 'video',
      welcomeVideo:
        'https://staging-4mf-bucket.s3.us-west-1.amazonaws.com/videos/20220728020913-203-woman-47431/1920x1080.mp4?AWSAccessKeyId=AKIAZJ5CPRFFUGZSJ4UE&Expires=1673023900&Signature=t8ew%2F6JSz1P50YEoz0n6cd9yMvQ%3D',
      _id: '624c2e7170672e6923c757c5',
    },
    {
      age: 23,
      createdAt: '2022-04-05T11:56:33.956Z',
      description:
        'So This Is The 4my.fans Page!! You Wont Have The Same Problems U Have Had With The Other Sites Here. This Should Be Easier To Use And Easier To Find What You’re Looking For. I Update My Page 2 To Three Times A Week For Constant Simulation … I Have No End In Sight For Boys Than Wana Be In Videos, So Lets Keep Them Cumming!!',
      imageFullPath: 'https://formyfans.s3-us-west-1.amazonaws.com/20221229083805-545-avatar',
      imageMediumPath: 'https://formyfans.s3-us-west-1.amazonaws.com/20221229083805-545-avatar.320x320.jpeg',
      imageThumbPath: 'https://formyfans.s3-us-west-1.amazonaws.com/20221229083805-545-avatar.135x135.png',
      isPayable: true,
      isPerformer: true,
      isShowFans: true,
      name: 'Maiia',
      photo: 'https://staging-4mf-bucket.s3.us-west-1.amazonaws.com/20221130034213-791-avatar.135x135.png',
      role: 'performer',
      sex: 'MTF',
      sexualPreference: 'gay',
      sort: 0,
      status: 'active',
      subscriptionMonthlyPrice: 9.99,
      subscriptionTrialEnabled: true,
      subscriptionTrialPrice: 2.99,
      subscriptionYearlyPrice: 49.99,
      totalLike: 2,
      totalSubscriber: 2,
      totalVideo: 0,
      totalView: 196,
      updatedAt: '2022-11-30T08:42:39.971Z',
      username: 'maiia',
      weight: '130',
      welcomeOption: 'video',
      welcomeVideo:
        'https://staging-4mf-bucket.s3.us-west-1.amazonaws.com/videos/20220728020913-203-woman-47431/1920x1080.mp4?AWSAccessKeyId=AKIAZJ5CPRFFUGZSJ4UE&Expires=1673023900&Signature=t8ew%2F6JSz1P50YEoz0n6cd9yMvQ%3D',
      _id: '624c2e7170672e6923c757c5',
    },
    {
      age: 23,
      createdAt: '2022-04-05T11:56:33.956Z',
      description:
        'So This Is The 4my.fans Page!! You Wont Have The Same Problems U Have Had With The Other Sites Here. This Should Be Easier To Use And Easier To Find What You’re Looking For. I Update My Page 2 To Three Times A Week For Constant Simulation … I Have No End In Sight For Boys Than Wana Be In Videos, So Lets Keep Them Cumming!!',
      imageFullPath: 'https://formyfans.s3.us-west-1.amazonaws.com/2021213111234_avatar',
      imageMediumPath: 'https://formyfans.s3.us-west-1.amazonaws.com/2021213111234_avatar',
      imageThumbPath: 'https://formyfans.s3.us-west-1.amazonaws.com/2021213111234_avatar',
      isPayable: true,
      isPerformer: true,
      isShowFans: true,
      name: 'Eddie',
      photo: 'https://staging-4mf-bucket.s3.us-west-1.amazonaws.com/20221130034213-791-avatar.135x135.png',
      role: 'performer',
      sex: 'MTF',
      sexualPreference: 'gay',
      sort: 0,
      status: 'active',
      subscriptionMonthlyPrice: 9.99,
      subscriptionTrialEnabled: true,
      subscriptionTrialPrice: 2.99,
      subscriptionYearlyPrice: 49.99,
      totalLike: 132,
      totalSubscriber: 2,
      totalVideo: 60,
      totalView: 9290,
      updatedAt: '2022-11-30T08:42:39.971Z',
      username: 'knitflexxxandchill',
      weight: '130',
      welcomeOption: 'video',
      welcomeVideo:
        'https://staging-4mf-bucket.s3.us-west-1.amazonaws.com/videos/20220728020913-203-woman-47431/1920x1080.mp4?AWSAccessKeyId=AKIAZJ5CPRFFUGZSJ4UE&Expires=1673023900&Signature=t8ew%2F6JSz1P50YEoz0n6cd9yMvQ%3D',
      _id: '624c2e7170672e6923c757c5',
    },
    {
      age: 23,
      createdAt: '2022-04-05T11:56:33.956Z',
      description:
        'So This Is The 4my.fans Page!! You Wont Have The Same Problems U Have Had With The Other Sites Here. This Should Be Easier To Use And Easier To Find What You’re Looking For. I Update My Page 2 To Three Times A Week For Constant Simulation … I Have No End In Sight For Boys Than Wana Be In Videos, So Lets Keep Them Cumming!!',
      imageFullPath: 'https://formyfans.s3-us-west-1.amazonaws.com/20221006105845-792-avatar',
      imageMediumPath: 'https://formyfans.s3-us-west-1.amazonaws.com/20221006105845-792-avatar.320x320.jpeg',
      imageThumbPath: 'https://formyfans.s3-us-west-1.amazonaws.com/20221006105845-792-avatar.135x135.png',
      isPayable: true,
      isPerformer: true,
      isShowFans: true,
      name: 'Kelly',
      photo: 'https://staging-4mf-bucket.s3.us-west-1.amazonaws.com/20221130034213-791-avatar.135x135.png',
      role: 'performer',
      sex: 'MTF',
      sexualPreference: 'gay',
      sort: 0,
      status: 'active',
      subscriptionMonthlyPrice: 9.99,
      subscriptionTrialEnabled: true,
      subscriptionTrialPrice: 2.99,
      subscriptionYearlyPrice: 49.99,
      totalLike: 3,
      totalSubscriber: 2,
      totalVideo: 34,
      totalView: 475,
      updatedAt: '2022-11-30T08:42:39.971Z',
      username: 'kellynakatomi',
      weight: '130',
      welcomeOption: 'video',
      welcomeVideo:
        'https://staging-4mf-bucket.s3.us-west-1.amazonaws.com/videos/20220728020913-203-woman-47431/1920x1080.mp4?AWSAccessKeyId=AKIAZJ5CPRFFUGZSJ4UE&Expires=1673023900&Signature=t8ew%2F6JSz1P50YEoz0n6cd9yMvQ%3D',
      _id: '624c2e7170672e6923c757c5',
    },
  ];

  orders = {
    resent: 'Recent',
    // rate: this.$t('rate'),
    popular: this.$t('popularity'),
    // name: this.$t('name'),
    relevance: this.$t('relevance'),
  };
  preferences = {
    all: this.$t('allSexualPreferences'),
    gay: this.$t('gay'),
    straight: this.$t('straight'),
    bisexual: this.$t('bisexual'),
  };
  genders = {
    all: this.$t('allGenders'),
    male: this.$t('male'),
    female: this.$t('female'),
    MTF: this.$t('MTF'),
    FTM: this.$t('FTM'),
  };
  params = {
    order: 'relevance',
    sexualOrientation: 'all',
    preference: 'all',
    keyword: '',
  };
  page = 1;
  take = 6;
  randomPromoIndex = this.randomInt(4, 12);
  models: ModelDataInterface[] = [];
  totalModels = 0;
  isRequestSend = false;
  searchQuery = '';
  debouncedSearch = this.debounce(this.changeSearch, 700);
  allModelsCount = 0;

  mounted() {
    this.getFirstPage();
    this.getModelCount();
  }

  @Watch('searchQuery')
  onSearchQueryChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.debouncedSearch();
    }
  }

  get isPerformer(): boolean {
    return vxm.user.role === 'performer';
  }

  loadMoreModels() {
    if (!this.isRequestSend && this.totalModels > this.models.length) {
      this.getModels();
    }
  }

  randomInt(min: number, max: number) {
    return min + Math.floor((max - min) * Math.random());
  }

  capFirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getFirstPage() {
    this.page = 1;
    this.models = [];
    this.getModels();
  }

  getModelCount() {
    vxm.fan
      .getPerformerCounts({
        all: '',
        male: '',
        female: '',
        mtf: '',
        ftm: '',
      })
      .then((res) => {
        this.allModelsCount = res.data;
      })
      .catch((error) => {
        return error;
      });
  }

  getModels() {
    const params: PerformersParamsInterface = {
      status: 'active',
      sort: this.params.order,
      take: this.take,
      page: this.page,
      keyword: this.params.keyword,
      sexualOrientation: this.params.sexualOrientation === 'all' ? '' : this.params.sexualOrientation,
    };
    this.isRequestSend = true;

    vxm.fan
      .getPerformers(params)
      .then((res) => {
        //this.models.push(...res.data.items);
        // console.log(...res.data.items);
        if (this.displayCount > 1) {
          this.hardData.push(...res.data.items);
        }
        this.displayCount = this.displayCount + 1;
        this.totalModels = res.data.count;
        this.page++;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
        if (this.totalModels > this.models.length) {
          this.$nextTick(() => {
            const hasVScroll = document.body.scrollHeight > window.innerHeight;
            if (!hasVScroll) {
              this.getModels();
            }
          });
        }
      });
  }

  changeSearch() {
    this.params.keyword = this.searchQuery;
    this.getFirstPage();
  }
}
