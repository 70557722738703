




























































import {Component, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import {PhotoShortInterface} from '@/types/photoInterface';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import Vuelidate from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import heic2any from 'heic2any';

Vue.use(Vuelidate);

const defaultForm: PhotoShortInterface = {
  description: '',
  file: [],
  performer: [],
  sort: 0,
  status: 'active',
  name: '',
  isForSale: false,
};

@Component({
  components: {
    ProgressBar,
    Modal,
    Loader,
  },
  validations: {
    picsForms: {
      $each: {
        name: {required},
      },
    },
  },
})
export default class UploadPhoto extends Vue {
  picsForms: PhotoShortInterface[] = [];
  uploadInProgress = false;
  multiLoadingProgress: number[] = [];
  expandedPhoto = 0;
  copiedPhoto = 0;
  uploadError = false;
  showDropZone = false;
  heicLoader = false;

  mounted() {
    // default open up select files
    // this.$nextTick(() => {
    //   (this.$refs.file as HTMLInputElement).click();
    // });
  }

  handleFileUpload() {
    (this.$refs.file as any).files.forEach((file) => {
      this.createForm(file);
    });
    (this.$refs.file as any).value = '';
  }

  createForm(file: File) {
    const form = JSON.parse(JSON.stringify(defaultForm));
    if (file.type === 'image/heic' || (file.type === '' && file.name.includes('.HEIC'))) {
      this.heicLoader = true;
      heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.8,
      }).then((res) => {
        form.file.push(res);
        form.file[0].name = file.name;
        form.name = file.name;
        form.performer.push(vxm.user.data._id);
        const reader = new FileReader();
        reader.onload = (e: any) => {
          form.base64 = e.target.result;
          this.picsForms.push(form);
        };
        reader.readAsDataURL(res as Blob);
        this.heicLoader = false;
      });
    } else {
      form.file.push(file);
      form.name = file.name;
      form.performer.push(vxm.user.data._id);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        form.base64 = e.target.result;
        this.picsForms.push(form);
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage(i: number) {
    if (!this.uploadInProgress) {
      this.picsForms.splice(i, 1);
    }
  }

  expandDetails(i: number) {
    if (!this.uploadInProgress) {
      this.expandedPhoto = i === this.expandedPhoto ? 0 : i;
    }
  }

  copyPastContent(i: number) {
    if (!this.uploadInProgress) {
      if (this.copiedPhoto && this.copiedPhoto !== i) {
        const file = this.picsForms[i - 1].file[0];
        const base64 = this.picsForms[i - 1].base64;
        this.picsForms[i - 1] = JSON.parse(JSON.stringify(this.picsForms[this.copiedPhoto - 1]));
        this.picsForms[i - 1].file[0] = file;
        this.picsForms[i - 1].base64 = base64;
        this.copiedPhoto = 0;
      } else {
        this.copiedPhoto = i;
      }
    }
  }

  async publish() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.uploadInProgress = true;
    this.multiLoadingProgress = [];
    this.expandedPhoto = 0;
    this.copiedPhoto = 0;
    for (let i = 0; i < this.picsForms.length; i++) {
      this.multiLoadingProgress.push(0);
      const file = this.picsForms[i].file;
      const data = JSON.parse(JSON.stringify(this.picsForms[i]));
      data.file = file;
      delete data.base64;
      await vxm.model
        .uploadPhoto({
          data: data,
          uploadProgressCallback: (progressEvent) => {
            Vue.set(this.multiLoadingProgress, i, Math.round((progressEvent.loaded / progressEvent.total) * 100));
          },
        })
        .then(
          () => {
            this.multiLoadingProgress[i] = 100;
          },
          () => {
            this.uploadError = true;
            this.picsForms[i].hasError = true;
          },
        );
    }
    if (this.uploadError) {
      this.picsForms = this.picsForms.filter((x) => x.hasError);
      this.multiLoadingProgress = [];
    }
    if (this.$route.name === 'model-pics') {
      vxm.model.setNeedUpdatePhotoList(true);
    }
    this.uploadInProgress = false;
    if (!this.uploadError) {
      this.$notify({
        group: 'foo',
        title: 'Your Upload has finished.',
        duration: 5000,
        type: 'success',
        text:
          'The system will now generate and convert the files for faster access. Conversions might take a while to populate.',
      });
      this.picsForms = [];
      this.close();
    }
  }

  dropHandler(ev: DragEvent) {
    if (ev.dataTransfer) {
      if (ev.dataTransfer.items) {
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === 'file') {
            const file = ev.dataTransfer.items[i].getAsFile() as File;
            if (file.type.split('/')[0] === 'image') {
              this.createForm(file);
            }
          }
        }
      } else {
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          const file = ev.dataTransfer.files[i];
          if (file.type.split('/')[0] === 'image') {
            this.createForm(file);
          }
        }
      }
    }
    this.showDropZone = false;
  }

  dragOverHandler() {
    this.showDropZone = true;
  }

  close() {
    this.$emit('closed');
  }
}
