






















































import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import {vxm} from '@/store';
import {CategoryInterface} from '@/types/CategoryInterface';
import {ModelDataInterface} from '@/types/userDataInterface';
import {ShortVideoInterface} from '@/types/videoInterface';
import Loader from '@/components/Loader.vue';
import VideoForm from '@/components/VideoForm.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import {GendersEnum} from '@/types/enums/GendersEnum';
import {PreferencesEnum} from '@/types/enums/PreferencesEnum';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

const defaultForm: ShortVideoInterface = {
  type: 'recent',
  price: 1.99,
  name: '',
  categories: [],
  description: '',
  twitterText: '',
  tags: [],
  performer: [],
  isSchedule: false,
  schedule: '',
  status: 'inactive',
  imageThumbPath: null as any,
  imageFullPath: null as any,
  sort: 0,
  isSaleVideo: false,
  file: {
    image: '',
    fileFullPath: null as any,
  },
  vidType: 'vids',
  sexualOrientation: GendersEnum.MALE,
  sexualPreference: PreferencesEnum.GAY,
};
@Component({
  components: {
    ConfirmationModal,
    ProgressBar,
    Modal,
    Loader,
    VideoForm,
  },
})
export default class UploadVideo extends Vue {
  uploadInProgress = false;
  categories: CategoryInterface[] = [];
  performers: ModelDataInterface[] = [];
  videosList: ShortVideoInterface[] = [];
  expandedVideo = 0;
  copiedVideo = 0;
  multiLoadingProgress: number[] = [];
  uploadError = false;
  showDropZone = false;
  formErrors = false;

  @Prop({default: 'vids', type: String}) readonly vidType!: string;

  get isMobile() {
    const reg = /android|iphone|ipad|ipod/i;
    return reg.test(navigator.userAgent);
  }

  get showUploadErrorModal() {
    return vxm.model.showUploadErrorModal;
  }

  mounted() {
    vxm.fan
      .getCategories()
      .then((res) => {
        this.categories = res.data;
      })
      .catch((error) => {
        return error;
      });

    // if (this.isMobile) {
    //   this.$nextTick(() => {
    //     (this.$refs.file as HTMLInputElement).click();
    //   });
    // }
  }

  beforeDestroy() {
    if (this.uploadInProgress) {
      vxm.model.setAbortUpload(true);
    }
  }

  onFormError(e: boolean) {
    this.formErrors = e;
  }

  uploadErrorConfirm(confirm: boolean) {
    vxm.model.uploadErrorActionResolve(confirm);
    vxm.model.setShowUploadErrorModal(false);
  }

  async publish() {
    this.uploadInProgress = true;
    this.expandedVideo = 0;
    this.copiedVideo = 0;
    this.multiLoadingProgress = [];
    for (let i = 0; i < this.videosList.length; i++) {
      this.multiLoadingProgress.push(0);
      if (this.videosList[i].vidType === 'clips') {
        const data = {
          description: this.videosList[i].description,
          file: this.videosList[i].file.fileFullPath,
          name: this.videosList[i].name,
          status: 'active',
          sexualOrientation: this.videosList[i].sexualOrientation,
          sexualPreference: this.videosList[i].sexualPreference,
          twitterText: this.videosList[i].twitterText,
        };
        await vxm.model
          .uploadAffiliateContent({
            data: data,
            uploadProgressCallback: (progressEvent) => {
              Vue.set(this.multiLoadingProgress, i, Math.round((progressEvent.loaded / progressEvent.total) * 100));
            },
          })
          .then(
            () => {
              this.multiLoadingProgress[i] = 100;
            },
            () => {
              Vue.delete(this.multiLoadingProgress, i);
              this.uploadError = true;
              this.videosList[i].hasError = true;
            },
          );
      } else {
        const file = this.videosList[i].file.fileFullPath;
        const data = JSON.parse(JSON.stringify(this.videosList[i]));
        data.performer.push(vxm.user.data._id);
        delete data.vidType;
        delete data.sexualOrientation;
        delete data.sexualPreference;
        data.file.fileFullPath = file;
        data.isSaleVideo = this.vidType === 'vids-plus';
        await vxm.model
          .uploadVideo({
            data: data,
            uploadProgressCallback: (progressEvent) => {
              Vue.set(this.multiLoadingProgress, i, Math.round((progressEvent.loaded / progressEvent.total) * 100));
            },
          })
          .then(
            () => {
              this.multiLoadingProgress[i] = 100;
              // if (ss.status === 200) {
              //   this.uploadError = false;
              // }
            },
            () => {
              Vue.delete(this.multiLoadingProgress, i);
              // if (jj.status !== 200) {
              //   this.uploadError = true;
              // }
              this.uploadError = true;
              this.videosList[i].hasError = true;
              this.close();
            },
          );
      }
      if (vxm.model.abortUpload) {
        vxm.model.setAbortUpload(false);
        break;
      }
    }
    if (!this.uploadError) {
      this.$notify({
        group: 'foo',
        title: 'Your Upload has finished.',
        duration: 5000,
        type: 'success',
        text:
          'The system will now generate and convert the files for faster access. Conversions might take a while to populate.',
      });
      this.videosList = [];
      this.close();
    }
    if (this.uploadError) {
      this.videosList = this.videosList.filter((x) => x.hasError);
      this.close();
    }
    if (
      this.$route.name === 'manage-model-videos' ||
      this.$route.name === 'manage-model-tagged-videos' ||
      this.$route.name === 'manage-model-clips'
    ) {
      vxm.model.setNeedUpdateVideoList(true);
    }
    this.uploadInProgress = false;
  }

  close() {
    this.$emit('closed');
  }

  handleFileUpload() {
    (this.$refs.file as any).files.forEach((file) => {
      this.createForm(file);
    });
    (this.$refs.file as any).value = '';
  }

  createForm(file: File) {
    const form = JSON.parse(JSON.stringify(defaultForm));
    form.vidType = this.vidType;
    form.file.fileFullPath = file;
    form.name = file.name;
    form.categories[0] = this.categories[0]._id;
    this.videosList.push(form);
  }

  expandVideo(i: number) {
    if (!this.uploadInProgress) {
      this.expandedVideo = i === this.expandedVideo ? 0 : i;
    }
  }

  removeVideo(i: number) {
    if (!this.uploadInProgress) {
      this.videosList.splice(i, 1);
      this.copiedVideo = 0;
    }
  }

  copyPastContent(i: number) {
    if (!this.uploadInProgress) {
      if (this.copiedVideo && this.copiedVideo !== i) {
        const file = this.videosList[i - 1].file.fileFullPath;
        this.videosList[i - 1] = JSON.parse(JSON.stringify(this.videosList[this.copiedVideo - 1]));
        this.videosList[i - 1].file.fileFullPath = file;
        this.copiedVideo = 0;
      } else {
        this.copiedVideo = i;
      }
    }
  }

  dropHandler(ev: DragEvent) {
    if (ev.dataTransfer) {
      if (ev.dataTransfer.items) {
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === 'file') {
            const file = ev.dataTransfer.items[i].getAsFile() as File;
            if (file.type.split('/')[0] === 'video') {
              this.createForm(file);
            }
          }
        }
      } else {
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          const file = ev.dataTransfer.files[i];
          if (file.type.split('/')[0] === 'video') {
            this.createForm(file);
          }
        }
      }
    }
    this.showDropZone = false;
  }

  dragOverHandler() {
    this.showDropZone = true;
  }
}
