




































































import {Component, Vue} from 'vue-property-decorator';
import SearchDropDownNew from '@/components/SearchDropDownNew.vue';
import ContactUsModalNew from '@/components/modals/ContactUsModalNew.vue';
import {vxm} from '@/store';

@Component({
  components: {
    SearchDropDownNew,
    ContactUsModalNew,
  },
})
export default class HeaderMenuNew extends Vue {
  showHeaderMenu = false;
  showMenu = false;
  showContactUs = false;
  isMobile = false;

  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  get isModel(): boolean {
    return vxm.user.data.role === 'performer';
  }

  get profileLink(): string {
    return `/${vxm.user.data.username}`;
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  onResize() {
    this.isMobile = window.innerWidth <= 1023;
  }

  logout() {
    this.$store.dispatch('resetStore').then(() => {
      localStorage.removeItem('model');
      localStorage.removeItem('fan');
      this.showMenu = false;
      this.$socket.disconnect();
      this.$router.push('/login');
    });
  }
}
