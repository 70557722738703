









































































import {Component, Vue, Watch} from 'vue-property-decorator';
import PostCard from '@/components/PostCard.vue';
import DropDown from '@/components/DropDown.vue';
// import TopModels from '@/components/TopModels.vue';
import {vxm} from '@/store';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import Loader from '@/components/Loader.vue';
import {PhotoInterface} from '@/types/photoInterface';
import {VideoInterface} from '@/types/videoInterface';

// New
// import TopModelsNew from '@/components/TopModelsNew.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {PerformersSearchParamsInterface} from '@/types/PerformersSearchParamsInterface';
import {PerformersParamsInterface} from '@/types/PerformersParamsInterface';
import PostCardNew from '@/components/PostCardNew.vue';
import ModelsNew from '@/views/fan-views/ModelsNew.vue';
import CookieLaw from 'vue-cookie-law';

@Component({
  components: {
    Loader,
    PostCard,
    DropDown,
    // TopModelsNew,
    PostCardNew,
    ModelsNew,
    CookieLaw,
  },
})
export default class HomeNew extends Vue {
  featuredTitle = 'Featured';
  feedTitle = 'Feed';
  modelTitle = 'Models';
  viewType = 'grid';
  posts: Array<AffiliateContentResponse | PhotoInterface | VideoInterface> = [];
  isMobile = false;
  isRequestSend = false;
  params = {
    page: 1,
    take: 17,
  };
  totalPosts = 0;
  topModels: Array<ModelDataInterface> = [];
  isLoadMoreModels = false;
  featuredFeeds: Array<AffiliateContentResponse | PerformersParamsInterface> = [];
  randomPromoIndex = this.randomInt(3, 12);
  twoFeedsPosts: Array<AffiliateContentResponse | VideoInterface> = [];

  mounted() {
    this.getFirstPage();
    this.getModelData();
    this.getFeaturedFeeds();
    this.getTwoFeedsData();
    // window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }
  setCookieFalse(props): void {
    localStorage.setItem('cookie:accepted', 'false');
    props.close();
  }
  destroyed() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    //const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (!this.isRequestSend && this.totalPosts > this.posts.length) {
      this.getData();
      this.$gtag.event('feed page', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_category: 'lazy loading',
      });
    }
  }

  // get random index to create blank space in grid
  randomInt(min: number, max: number) {
    return min + Math.floor((max - min) * Math.random());
  }

  onResize() {
    this.isMobile = window.innerWidth <= 1023;
  }

  getFirstPage() {
    this.params.page = 1;
    this.posts = [];
    this.getData();
  }

  // Load more data on button click
  getMoreData() {
    this.isRequestSend = true;
    this.getData();
  }

  // Load more model data on button click
  getMoreModelData() {
    this.isLoadMoreModels = true;
    this.getModelData();
  }

  get paymentSuccessCount() {
    return this.$store.state.paymentSuccessCount;
  }

  @Watch('paymentSuccessCount') onPaymentMessage() {
    const take = this.params.take as number;
    this.isRequestSend = true;
    vxm.fan
      .searchSubscribedMedia({...this.params, page: 1, take: take})
      .then((res) => {
        this.totalPosts = res.data.count;
        this.posts = res.data.items;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  @Watch('paymentSuccessCount') onPaymentMessages() {
    const take = this.params.take as number;
    vxm.fan
      .getFeaturedContents({status: 'active', videoType: 'vids', page: 1, take: take})
      .then((res) => {
        this.featuredFeeds.unshift(res.data);
      })
      .catch((error) => {
        return error;
      });
  }

  getData() {
    const params = {...this.params};
    this.isRequestSend = true;
    vxm.fan
      .searchSubscribedMedia(params)
      .then((res) => {
        this.totalPosts = res.data.count;
        this.posts.push(...res.data.items);
        (this.params.page as number)++;
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        this.isRequestSend = false;
      });
  }

  getModelData() {
    if (!vxm.fan.leaderboard.length) {
      const paramst: PerformersSearchParamsInterface = {
        page: 1,
        take: 6,
        sort: 'popular',
        status: 'active',
      };
      this.isLoadMoreModels = true;

      vxm.fan
        .getLeaderboard(paramst)
        .then((res) => {
          this.topModels.push(...res.data.items);
          (paramst.page as number)++;
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isLoadMoreModels = false;
        });
    } else {
      this.topModels = vxm.fan.leaderboard;
      this.isLoadMoreModels = false;
    }
  }

  getFeaturedFeeds() {
    vxm.fan
      .getFeaturedContents({
        videoType: 'vids',
        page: 1,
        take: 1,
        status: 'active',
      })
      .then((res) => {
        this.featuredFeeds.push(res.data);
      })
      .catch((error) => {
        return error;
      });
  }

  getTwoFeedsData() {
    const params = {
      page: 1,
      take: 17,
    };
    this.isRequestSend = true;
    vxm.fan
      .searchSubscribedMedia(params)
      .then((res) => {
        this.totalPosts = res.data.count;
        this.twoFeedsPosts.push(
          ...res.data.items.filter((item) => {
            item.mediaType === 'vids';
            return item;
          }),
        );
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  switchView(viewType: string) {
    this.viewType = viewType;
  }
}
