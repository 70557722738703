








































































































import {Component, Vue} from 'vue-property-decorator';
import SearchDropDownNew from '@/components/SearchDropDownNew.vue';
import ContactUsModalNew from '@/components/modals/ContactUsModalNew.vue';
import UploadVideo from '@/components/modals/UploadVideo.vue';
import UploadPicture from '@/components/modals/UploadPhoto.vue';
import CreateAlbum from '@/components/modals/CreateAlbum.vue';
import {vxm} from '@/store';

@Component({
  components: {
    SearchDropDownNew,
    ContactUsModalNew,
    UploadVideo,
    UploadPicture,
    CreateAlbum,
  },
})
export default class HeaderMenuNew extends Vue {
  showHeaderMenu = false;
  showMenu = false;
  showContactUs = false;
  showUploadVideo = false;
  showUploadPicture = false;
  showCreateAlbum = false;
  selectedVidType = 'vids';
  isMobile = false;

  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  get isModel(): boolean {
    return vxm.user.data.role === 'performer';
  }

  get profileLink(): string {
    return `/${vxm.user.data.username}`;
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  onResize() {
    this.isMobile = window.innerWidth <= 1023;
  }

  showUploadVideoModal(type: string) {
    this.showUploadVideo = true;
    this.selectedVidType = type;
    this.$gtag.event(`upload ${type}`, {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: 'show modal',
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_label: vxm.user.name,
    });
  }

  logout() {
    this.$store.dispatch('resetStore').then(() => {
      localStorage.removeItem('model');
      localStorage.removeItem('fan');
      this.showMenu = false;
      this.$socket.disconnect();
      this.$router.push('/login');
    });
  }
}
