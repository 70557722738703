




























































import {Component, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import {PhotoShortInterface} from '@/types/photoInterface';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import {maxLength, minLength, required} from 'vuelidate/lib/validators';
import heic2any from 'heic2any';

const defaultForm: PhotoShortInterface = {
  description: '',
  file: [],
  performer: [],
  photoUrls: [],
  sort: 0,
  status: 'inactive',
  name: '',
  salePrice: '',
  isForSale: true,
};
const moreThan = (param) => (value) => value >= param;
const lessThan = (param) => (value) => value <= param;

@Component({
  components: {
    ProgressBar,
    Modal,
    Loader,
  },
  validations: {
    picsForm: {
      name: {
        required,
      },
      salePrice: {
        required,
        moreThan: moreThan(1.99),
        lessThan: lessThan(49.99),
      },
      photoUrls: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(50),
      },
    },
  },
})
export default class CreateAlbum extends Vue {
  picsForm: PhotoShortInterface = JSON.parse(JSON.stringify(defaultForm));
  uploadInProgress = false;
  loadingProgress = 0;
  uploadError = false;
  heicLoader = false;
  showDropZone = false;

  async handleFileUpload() {
    for (const file of (this.$refs.file as any).files) {
      await this.createForm(file);
    }
    (this.$refs.file as any).value = '';
  }

  async createForm(file: File) {
    if (file.type === 'image/heic' || (file.type === '' && file.name.includes('.HEIC'))) {
      this.heicLoader = true;
      await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.8,
      }).then((res) => {
        this.picsForm.file.push(res);
        this.picsForm.file[this.picsForm.file.length - 1].name = file.name;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          (this.picsForm.photoUrls as Array<string>).push(e.target.result);
        };
        reader.readAsDataURL(res as Blob);
        this.heicLoader = false;
      });
    } else {
      this.picsForm.file.push(file);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        (this.picsForm.photoUrls as Array<string>).push(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage(i: number) {
    if (!this.uploadInProgress) {
      this.picsForm.file.splice(i, 1);
      (this.picsForm.photoUrls as Array<string>).splice(i, 1);
    }
  }

  async publish() {
    this.$v.picsForm.$touch();
    if (!this.$v.picsForm.$invalid) {
      this.uploadInProgress = true;
      this.loadingProgress = 0;
      const file = this.picsForm.file;
      const data = JSON.parse(JSON.stringify(this.picsForm));
      delete data.photoUrls;
      data.file = file;
      data.performer.unshift(vxm.user.data._id);
      await vxm.model
        .uploadPhoto({
          data: data,
          uploadProgressCallback: (progressEvent) => {
            this.loadingProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          },
        })
        .then(
          () => {
            this.loadingProgress = 100;
          },
          () => {
            this.uploadError = true;
            this.picsForm.hasError = true;
          },
        );
      if (this.uploadError) {
        this.loadingProgress = 0;
      }
      if (this.$route.name === 'model-albums') {
        vxm.model
          .getMyAlbums({
            page: 1,
            take: 15,
            keyword: '',
            performerId: vxm.user.data._id,
            isForSale: true,
          })
          .catch((error) => {
            return error;
          });
      }
      this.uploadInProgress = false;
      if (!this.uploadError) {
        this.$notify({
          group: 'foo',
          title: 'Your Upload has finished.',
          duration: 5000,
          type: 'success',
          text:
            'The system will now generate and convert the files for faster access. Conversions might take a while to populate.',
        });
        this.picsForm = JSON.parse(JSON.stringify(defaultForm));
        this.close();
      }
    }
  }

  dropHandler(ev: DragEvent) {
    if (ev.dataTransfer) {
      if (ev.dataTransfer.items) {
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === 'file') {
            const file = ev.dataTransfer.items[i].getAsFile() as File;
            if (file.type.split('/')[0] === 'image') {
              this.createForm(file);
            }
          }
        }
      } else {
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          const file = ev.dataTransfer.files[i];
          if (file.type.split('/')[0] === 'image') {
            this.createForm(file);
          }
        }
      }
    }
    this.showDropZone = false;
  }

  dragOverHandler() {
    this.showDropZone = true;
  }

  close() {
    this.$emit('closed');
  }
}
