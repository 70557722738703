



















































import {Component, Mixins, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import {ModelDataInterface} from '@/types/userDataInterface';
// import {ProductInterface} from '@/types/productInterface';
import {VideoInterface} from '@/types/videoInterface';
import {Pagination} from '@/types/Pagination';
import DebounceMixin from '@/mixins/DebounceMixin';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';

@Component
export default class SearchDropDownNew extends Mixins(DebounceMixin, DefaultAvatarMixin) {
  loadingData = false;
  query = '';
  lastSearch = '';
  modelsResult: Pagination<ModelDataInterface[]> = {count: 0, items: []};
  // productsResult: Pagination<ProductInterface[]> = {count: 0, items: []};
  videosResult: Pagination<VideoInterface[]> = {count: 0, items: []};
  debouncedSearch = this.debounce(this.search, 700);

  mounted() {
    this.$nextTick(() => {
      // (this.$refs.queryInput as HTMLInputElement).focus();
    });
  }

  @Watch('query')
  onQueryUpdate() {
    this.debouncedSearch();
  }

  get isPerformer(): boolean {
    return vxm.user.role === 'performer';
  }

  search() {
    this.loadingData = true;
    if (!this.query) {
      this.loadingData = false;
      this.modelsResult = {count: 0, items: []};
      this.videosResult = {count: 0, items: []};
      // this.productsResult = {count: 0, items: []};
      return;
    }

    const params = {
      page: 1,
      take: 3,
      q: this.query,
    };

    this.lastSearch = this.query;

    this.$gtag.event('search', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: this.query,
    });

    vxm.fan
      .search({...params, type: 'performer'})
      .then((res) => {
        this.modelsResult = res.data;
        this.loadingData = false;
      })
      .catch((error) => {
        return error;
      });

    if (!this.isPerformer) {
      // vxm.fan.search({...params, type: 'product'}).then((res) => {
      //   this.productsResult = res.data;
      // }).catch((error) => {return error});

      vxm.fan
        .search({...params, type: 'video'})
        .then((res) => {
          this.videosResult = res.data;
          this.loadingData = false;
        })
        .catch((error) => {
          return error;
        });
    }
  }

  clearQuery() {
    this.query = '';
    (this.$refs.queryInput as HTMLInputElement).focus();
  }
}
